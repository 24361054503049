// src/components/AdminPanel.jsx
import React, { useState, useEffect, useRef } from 'react';

const AdminPanel = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [costumeList, setCostumeList] = useState([]);
  const [orderList, setOrderList] = useState([]); 
  const [costume, setCostume] = useState({
    title: '',
    size: '',
    condition: '',
    image: '',
    description: '',
  });
  const [editingId, setEditingId] = useState(null);
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [activeSection, setActiveSection] = useState('costumes'); // State for active section

  const [missionHeader, setMissionHeader] = useState('');
  const [mission, setMission] = useState('');
  const [missionImage, setMissionImage] = useState(null);
  const [aboutHeader, setAboutHeader] = useState('');
  const [about, setAbout] = useState('');
  const [about2, setAbout2] = useState('');
  const [aboutImage, setAboutImage] = useState(null);

  const formRef = useRef(null);

  useEffect(() => {
    if (sessionStorage.getItem('isAdminAuthenticated') === 'true') {
      setIsAuthenticated(true);
      fetchCostumes();
      fetchOrders();
      fetchContent();
    }
  }, []);

  useEffect(() => {
    if (activeSection === 'orders' && isAuthenticated) {
      fetchCostumes();
    }
  }, [activeSection]);

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        sessionStorage.setItem('isAdminAuthenticated', 'true');
        setIsAuthenticated(true);
        fetchCostumes();
        fetchOrders();
        fetchContent();
      } else {
        setAuthError('Invalid password. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setAuthError('Server error. Please try again later.');
    }
  };

  const fetchContent = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/content`);
    const data = await response.json();
    if (data) {
      setMissionHeader(data.missionHeader);
      setMission(data.mission);
      setAboutHeader(data.aboutHeader);
      setAbout(data.about);
      setAbout2(data.about2);
      setMissionImage(data.missionImage);
      setAboutImage(data.aboutImage);
    }
  };

  const uploadImage = async (file, section) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('section', section);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/content/upload`, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data.url;
  };

  const handleContentSave = async () => {
    const missionImageUrl = missionImage instanceof File ? await uploadImage(missionImage, 'mission') : missionImage;
    const aboutImageUrl = aboutImage instanceof File ? await uploadImage(aboutImage, 'about') : aboutImage;

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/content`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        missionHeader,
        mission,
        aboutHeader,
        about,
        about2,
        missionImage: missionImageUrl,
        aboutImage: aboutImageUrl,
      }),
    });
    setMessage(response.ok ? 'Content updated successfully!' : 'Error updating content');
  };

  const fetchCostumes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/costumes`);
      const data = await response.json();
      console.log('Fetched costumes:', data);
      setCostumeList(data);
    } catch (error) {
      console.error('Error fetching costumes:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/orders`);
      const data = await response.json();
      setOrderList(data); 
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleInputChange = (e) => {
    setCostume({ ...costume, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = costume.image;

      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);

        const uploadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
          method: 'POST',
          body: formData,
        });

        const uploadData = await uploadResponse.json();
        if (!uploadResponse.ok) throw new Error(uploadData.error || 'Image upload failed');
        imageUrl = uploadData.imageUrl;
      }

      const url = editingId
        ? `${process.env.REACT_APP_BACKEND_URL}/costumes/${editingId}`
        : `${process.env.REACT_APP_BACKEND_URL}/costumes`;

      const method = editingId ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...costume, image: imageUrl }),
      });

      if (response.ok) {
        setMessage(editingId ? 'Costume updated successfully!' : 'Costume added successfully!');
        setCostume({ title: '', size: '', condition: '', image: '', description: '' });
        setImageFile(null);
        setEditingId(null);
        fetchCostumes();
      } else {
        setMessage('Failed to save costume. Please try again.');
      }
    } catch (error) {
      console.error('Error saving costume:', error);
      setMessage('Error: Unable to save costume.');
    }
  };

  const editCostume = (costume) => {
    setCostume({
      ...costume,
      description: costume.description || '',
    });
    setEditingId(costume._id);

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const deleteCostume = async (id) => {
    if (!window.confirm('Are you sure you want to delete this costume?')) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/costumes/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setMessage('Costume deleted successfully!');
        fetchCostumes();
      } else {
        setMessage('Failed to delete costume.');
      }
    } catch (error) {
      console.error('Error deleting costume:', error);
      setMessage('Error: Unable to delete costume.');
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('isAdminAuthenticated');
    setIsAuthenticated(false);
  };
  
  if (!isAuthenticated) {
    return (
      <div className="container mx-auto p-4">
        <h2 className="text-xl font-bold mb-4">Admin Login</h2>
        {authError && <p className="text-red-500 mb-4">{authError}</p>}
        
        {/* Wrap the input in a form and attach handleLogin to onSubmit */}
        <form onSubmit={handleLogin}>
          <input
            type="password"
            placeholder="Enter admin password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border p-2 w-full mb-4"
          />
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
            Login
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 flex">
      {/* Sidebar Navigation */}
      <div className="w-1/4 p-4 bg-gray-200 rounded-lg mr-4">
        <h2 className="text-lg font-bold mb-4">Admin Panel</h2>
        <ul>
          <li
            className={`cursor-pointer p-2 ${activeSection === 'costumes' ? 'font-semibold' : ''}`}
            onClick={() => setActiveSection('costumes')}
          >
            Costume Management
          </li>
          <li
            className={`cursor-pointer p-2 ${activeSection === 'orders' ? 'font-semibold' : ''}`}
            onClick={() => {
              setActiveSection('orders');
              fetchCostumes();
              fetchOrders();
            }}
          >
            Order List
          </li>
          <li
            className={`cursor-pointer p-2 ${activeSection === 'content' ? 'font-semibold' : ''}`}
            onClick={() => setActiveSection('content')}
          >
            Edit Content
          </li>
          {/* Separator */}
          <hr className="my-4 border-t border-gray-400" />
          {/* Logout Link */}
          <li
            className="cursor-pointer p-2 text-red-600 hover:text-red-800"
            onClick={handleLogout}
          >
            Logout
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="w-3/4">
        {activeSection === 'costumes' && (
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8">
            <h1 className="text-2xl font-bold mb-4">Costume Management</h1>
            {message && <p className="text-red-500 mb-4">{message}</p>}
            <form onSubmit={handleSubmit} className="max-w-md mx-auto" ref={formRef}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  name="title"
                  value={costume.title}
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Size</label>
                <input
                  type="text"
                  name="size"
                  value={costume.size}
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Condition</label>
                <input
                  type="text"
                  name="condition"
                  value={costume.condition}
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Description</label>
                <textarea
                  name="description"
                  value={costume.description}
                  onChange={handleInputChange}
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Image</label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                  className="border p-2 w-full"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                {editingId ? 'Update Costume' : 'Add Costume'}
              </button>
            </form>

            <h2 className="text-xl font-bold mt-8">Costume List</h2>
            <ul className="mt-4">
              {costumeList.map((costume) => (
                <li key={costume._id} className="border p-4 rounded-lg mb-2">
                  <h3 className="text-lg font-semibold">{costume.title}</h3>
                  <p className="text-sm">Size: {costume.size}</p>
                  <p className="text-sm">Condition: {costume.condition}</p>
                  <button onClick={() => editCostume(costume)} className="mr-2 text-blue-500 hover:underline">
                    Edit
                  </button>
                  <button onClick={() => deleteCostume(costume._id)} className="text-red-500 hover:underline">
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeSection === 'orders' && (
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold mb-4">Order List</h1>
            <ul className="mt-4">
              {orderList.map((order) => (
                <li key={order._id} className="border p-4 rounded-lg mb-2">
                  <p className="text-lg font-semibold">Order Date: {new Date(order.orderDate).toLocaleString()}</p>
                  <p className="text-sm">Name: {order.shippingDetails.name}</p>
                  <p className="text-sm">Studio: {order.shippingDetails.studio}</p>
                  <p className="text-sm">Phone: {order.shippingDetails.phone}</p>
                  <p className="text-sm">Email: {order.shippingDetails.email}</p>
                  <p className="text-sm">Address: {order.shippingDetails.address}</p>
                  <h4 className="text-md font-semibold mt-2">Items:</h4>
                  <ul className="pl-4">
                    {order.items.map((item) => (
                      <li key={item.costumeId} className="text-sm flex items-center gap-4 my-2">
                        {item.image && (
                          <img 
                            src={item.image} 
                            alt={item.title} 
                            className="w-32 h-32 object-cover rounded"
                          />
                        )}
                        <span>- {item.title} (Size: {item.size}, Condition: {item.condition})</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeSection === 'content' && (
    <div className="admin-panel bg-gray-100 p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
    <h2 className="text-2xl font-bold mb-6">Edit Mission, About, and About2</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-lg font-semibold mb-1">Mission Header</label>
          <input
            type="text"
            value={missionHeader}
            onChange={(e) => setMissionHeader(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            placeholder="Enter Mission Header"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-1">Mission</label>
          <textarea
            value={mission}
            onChange={(e) => setMission(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 h-32 resize-none"
            placeholder="Enter Mission Text"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-1">About Header</label>
          <input
            type="text"
            value={aboutHeader}
            onChange={(e) => setAboutHeader(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            placeholder="Enter About Header"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-1">About</label>
          <textarea
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 h-32 resize-none"
            placeholder="Enter About Text"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-1">About Section 2</label>
          <textarea
            value={about2}
            onChange={(e) => setAbout2(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 h-32 resize-none"
            placeholder="Enter Additional About Text"
          />

        <div>
          <label className="block text-lg font-semibold mb-1">Image 1 ("No file chosen" means use the existing image)</label>
          <input
            type="file"
            onChange={(e) => setMissionImage(e.target.files[0])}
            className="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-1">Image 2 ("No file chosen" means use the existing image)</label>
          <input
            type="file"
            onChange={(e) => setAboutImage(e.target.files[0])}
            className="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>

        </div>

        <button
          onClick={handleContentSave}
          className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 mt-4 w-full"
        >
          Save Content
        </button>
        {message && <p className="text-green-600 mb-4">{message}</p>}
      </div>
    </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
